.button {
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
  background-color: var(--marine-blue);
  border-radius: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  font-size: 0.75rem;
  color: var(--alabaster);
  cursor: pointer;
}

.button:disabled {
  background-color: rgba(2, 41, 90, 0.5);
}

.button:hover,
.button:focus {
  background-color: #365e91;
}
