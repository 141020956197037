/* PlanSelection.module.css */

/* Container for switch */
.toggleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.switchContainer {
  display: flex;
  align-items: center;
}

.switchLabel {
  font-size: 14px;
  font-weight: bold;
  margin-right: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #0047ab;
  /* Your color for active state */
}

input:checked+.slider:before {
  transform: translateX(26px);
}

/* Styling for Form Inputs */
.formWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formGroup label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.formGroup input,
.formGroup select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s ease;
}

.formGroup input:focus,
.formGroup select:focus {
  border-color: #0047ab;
}

.errorField {
  border-color: red;
}

.errorMessage {
  color: red;
  font-size: 0.875rem;
}