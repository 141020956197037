.formWrapper {
  padding: 3.5rem 5rem;
  display: flex;
  flex-direction: column;
}

.formWrapper.mobile {
  padding: 2rem 1.5rem;
  margin: -7rem auto;
  width: 90%;
  height: 38rem;
  background: var(--white);
  border-radius: 0.5rem;
}
