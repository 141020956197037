.form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.form input {
  display: block;
}

.form label {
  display: flex;
  justify-content: space-between;
}

.form label span {
  color: var(--strawberry-red);
}

.form input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--light-gray);
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  color: var(--marine-blue);
  outline: none;
}

.form input + label {
  margin-top: 1.5rem;
}

.form input::placeholder {
  color: var(--cool-gray);
}

.form input:hover,
.form input:focus {
  border-color: var(--purplish-blue);
}

.form label {
  margin-bottom: 0.5rem;
  color: var(--marine-blue);
  font-weight: 500;
  cursor: pointer;
}

:global input.error {
  border-color: var(--strawberry-red);
}

.flexParent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  flex: 1;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: flex-end;
}


/* PersonalInfo.module.css */

.formGroup {
  margin-bottom: 20px;
}

.inlineInput {
  display: flex;
  align-items: center;
}

.inlineInput span {
  margin-left: 10px;
  font-size: 14px;
}

.radioGroup {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.radioGroup label {
  display: flex;
  align-items: center;
}

.radioGroup input {
  margin-right: 10px;
}

.toggleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.switchContainer {
  display: flex;
  align-items: center;
}

.switchLabel {
  font-size: 14px;
  font-weight: bold;
  margin-right: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #0047ab;
  /* Your color for active state */
}

input:checked+.slider:before {
  transform: translateX(26px);
}
