.thankYouWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.thankYouWrapper h1 {
  font-size: 2rem;
  font-weight: 700;
  color: var(--marine-blue);
  margin: 2rem 0;
}

.thankYouWrapper p {
  text-align: center;
  line-height: 1.5rem;
  color: var(--cool-gray);
}
