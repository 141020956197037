/* Header Styles */

.header {
    background-color: #002b80;
    /* Dark blue background */
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.header-content {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
}

.nav {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    transition: all 0.3s ease;
}

.nav-link {
    color: white;
    font-size: 1rem;
    font-weight: 500;
}

.nav-link:hover {
    color: #d4d4d4;
}

.quote-button {
    background-color: #ff5a5f;
    /* Red-orange button */
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}

.quote-button:hover {
    background-color: #e04e52;
}

/* Nav Toggle Button (Hamburger Icon) */
.nav-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Mobile Responsiveness */

@media (max-width: 768px) {
    .header-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .nav {
        display: none;
        /* Hide the nav by default on mobile */
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
    }

    .nav.nav-visible {
        display: flex;
        /* Show the nav when toggle is clicked */
    }

    .quote-button {
        margin-top: 1rem;
        width: 100%;
    }

    .nav-toggle {
        display: block;
        /* Show the toggle button on mobile */
    }
}

/* General styles for larger screens */
.quote-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: #0033a0;
    /* Blue background */
    color: white;
}

.quote-title {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.2;
}

.quote-subtitle {
    font-size: 1.25rem;
    margin-top: 1rem;
}

.highlight {
    color: #33a1ff;
    /* Lighter blue for the word "quote" */
}

.quote-image img {
    max-width: 300px;
    width: 100%;
    height: auto;
    border-radius: 50px;
}

/* Hide the component on mobile */
@media (max-width: 768px) {
    .quote-section {
        display: none;
    }
}