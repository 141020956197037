/* CSS RESET */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}

/* IMPORTS AND GENERAL STYLES */

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

:root {
  /* Primary colors */
  --marine-blue: hsl(213, 96%, 18%);
  --purplish-blue: hsl(243, 100%, 62%);
  --pastel-blue: hsl(228, 100%, 84%);
  --light-blue: hsl(206, 94%, 87%);
  --strawberry-red: hsl(354, 84%, 57%);
  /* Neutral colors */
  --cool-gray: hsl(231, 11%, 63%);
  --light-gray: hsl(229, 24%, 87%);
  --magnolia: hsl(217, 100%, 97%);
  --alabaster: hsl(231, 100%, 99%);
  --white: hsl(0, 0%, 100%);
}

img {
  max-width: 100%;
  display: block;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Ubuntu", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-width: 100%;
  background-color: var(--magnolia);
}

main {
  /* width: 60.75rem;
  height: 40.5rem; */
  background: var(--white);
  border-radius: 1rem;
  box-shadow: 0px 2px 10px 0px rgba(238, 244, 251 0.15);
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
}

@media (max-width: 940px) {
 

    body {
      /* Remove or override styles for mobile */
      display: block;
      min-height: auto;
      max-width: 100%;
      background-color: var(--white);
      /* or any other background */
      align-items: flex-start;
      /* If needed */
    }
  main {
    grid-template-columns: 1fr;
    width: auto;
    height: auto;
    background: var(--magnolia);
  }
}

/* TITLES AND INFOS */

.formTitle {
  font-size: 2rem;
  font-weight: 700;
  color: var(--marine-blue);
  margin-bottom: 1rem;
}

.formInfo {
  color: var(--cool-gray);
  margin-bottom: 2.5rem;
}

@media (max-width: 940px) {
  .formTitle {
    font-size: 1.75rem;
  }

  .formInfo {
    line-height: 1.75rem;
    margin-bottom: 1.75rem;
  }
}

/* REMOVING ARROWS ON NUMBER INPUTS */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* SLIDER */

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--marine-blue);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.slider:hover {
  opacity: 0.7;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
