.itemWrapper {
  display: flex;
  color: var(--alabaster);
  gap: 1.5rem;
}

.itemWrapper.mobile {
  gap: 0.875rem;
}

span {
  display: block;
}

.index {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--alabaster);
  padding: 0.5rem;
  border-radius: 50%;
  font-weight: 500;
}

.index.active {
  background: var(--light-blue);
  color: var(--marine-blue);
  border-color: var(--light-blue);
}

.stepWrapper {
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  font-size: 0.925rem;
  letter-spacing: 1.4px;
}

.stepWrapper span:first-child {
  font-size: 0.825rem;
  color: var(--magnolia);
  font-weight: 300;
  letter-spacing: initial;
}
